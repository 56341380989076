<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
      :max-width="computedComponentName === 'v-menu' ? 340 : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              tag
            </v-icon>

            Text Tags
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-text
          class="pt-4"
          :class="{ 'black--text': isThemeLight }"
        >
          <div class="mb-1 d-flex">
            <div class="d-flex align-center flex-grow-1">
              <v-icon
                :class="{ 'black--text': isThemeLight }"
                class="mr-1"
                left
              >
                volume_down
              </v-icon>

              Text Tags
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="isThemeLight ? 'black' : null"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>

              <span>
                Refine search results with only influencers that have mentioned or used these tags.
              </span>
            </v-tooltip>
          </div>

          <p>
            Please use @ for mentions and # for hashtags before each keyword.
          </p>

          <v-combobox
            :items="searchItems.input1"
            :loading="isLoadingSearchResults.input1"
            label="Tags"
            no-filter
            eager
            dense
            outlined
            :append-icon="null"
            @change="(v) => submitForm(v, 'should', 'input1')"
            :search-input.sync="search.input1"
            @update:search-input="doSearch('input1')"
            class="mt-3"
          >
            <!-- show loader while the data is loading -->
            <template v-slot:no-data>
              <div v-if="isLoadingSearchResults.input1" class="text-center py-3">
                <v-progress-circular
                  :width="3"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <div v-else class="text-center py-2">
                Press enter to select this.
              </div>
            </template>
          </v-combobox>

          <p>
            Excluding these ...
          </p>

          <v-combobox
            :items="searchItems.input2"
            :loading="isLoadingSearchResults.input2"
            label="Tags"
            no-filter
            eager
            dense
            outlined
            :append-icon="null"
            @change="(v) => submitForm(v, 'not', 'input2')"
            :search-input.sync="search.input2"
            @update:search-input="doSearch('input2')"
            class="mt-3"
          >
            <!-- show loader while the data is loading -->
            <template v-slot:no-data>
              <div v-if="isLoadingSearchResults.input2" class="text-center py-3">
                <v-progress-circular
                  :width="3"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <div v-else class="text-center py-2">
                Press enter to select this.
              </div>
            </template>
          </v-combobox>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// used throughout the code
const filterName = "textTags"

export default {
  name: "FilterTextTags",

  props: {
    platform: String
  },

  data: () => ({
    search: {
      input1: "",
      input2: ""
    },

    shouldShowModal: false,
    isLoadingSearchResults: {
      input1: false,
      input2: false
    },

    searchItems: {
      input1: [],
      input2: []
    },

    // for handling debounce on type
    _timerId: null,

    // to cancel previous request if a new one is needed
    abortControllers: []
  }),

  computed: {
    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    // get a list of all of them
    appliedFilters() {
      return this.$store.getters["influencerDiscovery/findFiltersByType"](filterName)
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.appliedFilters.length)
    }
  },

  methods: {
    /**
     * Tell whether this is a hashtag or not in input
     */
    isTag(key) {
      return this.search[key] && this.search[key].length && this.search[key][0] === '#'
    },

    /**
     * Takes an item and generates the text based on kind and weight
     */
    computeFilterText(value, action) {
      return `Tag: ${action === 'not' ? 'Not having - ' : ''} ${value}`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} value
     * @returns {void}
     */
    async submitForm(value, action, key) {
      if (!value || value.length < 2) {
        return
      }

      await this.$store.dispatch("influencerDiscovery/addFilter", {
        type: filterName,
        data: {
          color: "green lighten-5",
          icon: "tag",
          iconColor: "amber",
          text: this.computeFilterText(value, action),
          inputs: { value, action }
        }
      })

      // Finally hide the modal
      this.shouldShowModal = false

      // Reset the form values
      this.searchItems[key] = []
      this.search[key] = ""
    },

    /**
     * Delete the entry from vuex store
     *
     * @param {Object} item
     */
    removeFilterItem(item) {
      this.$store.dispatch("influencerDiscovery/removeFilter", item.id)
    },

    /**
     * Make API request to fetch the select options for autocomplete
     *
     * @returns {void}
     */
    doSearch(key) {
      // Update the form model value
      // this.search = (value || '').trim().toLowerCase()

      // cancel pending call
      if (this._timerId) {
        clearTimeout(this._timerId)
      }

      // delay new call 500ms
      this._timerId = setTimeout(async () => {
        // if input is empty, don't continue
        if (!this.search[key] || this.search[key].length < 2) {
          // reset the search results
          this.searchItems[key] = []

          return
        }

        // cancel pending network requests
        for (const item of this.abortControllers) {
          item.controller.abort()
        }

        // make network requests in vuex store
        const id = Symbol()
        const controller = new AbortController()

        // push this controller to the list
        this.abortControllers.push({ id, controller, query: this.search[key] })

        // show the loader while request is being made
        this.isLoadingSearchResults[key] = true

        try {
          const queryParams = new window.URLSearchParams({
            limit: 6,
            platform: this.platform,
            q: this.search[key]
          })

          const response = await axios({
            url: `/api/filters/${this.isTag(key) ? 'relevant-tags' : 'users'}/?` + queryParams,
            signal: controller.signal
          })

          // the API responds like
          this.searchItems[key].splice(
            0,
            this.searchItems[key].length,
            ...response.data.map((item) => {
              return this.isTag(key) ? item.tag : '@' + item.username
            })
          )
        } catch (error) {
          // log using the helper function
          logger({ type: "Network Error", error })
        } finally {
          this.isLoadingSearchResults[key] = false

          // remove the controller from cancellable array
          const index = this.abortControllers.findIndex((item) => item.id === id)
          this.abortControllers.splice(index, 1)
        }
      }, 500)
    },
  }
}
</script>
